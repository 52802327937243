import { ServiceContent } from "../contentful/contentful.types";

export const getPageSlugs = (locale: string) => ({
  homepage: { slug: `/${locale}` },
  services: { slug: `/${locale}/services` },
  portfolio: { slug: `/${locale}/portfolio` },
  recollections: { slug: `/${locale}/recollections` },
  contact: { slug: `/${locale}/contact` },
});

export const getServicesNavLabel = (
  content: ServiceContent[],
  identifier: string
) => {
  return content.find((c) => c.identifier === identifier);
};
