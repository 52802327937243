import React, { ReactNode, memo } from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { Options } from "@contentful/rich-text-react-renderer";

import {
  ContentBlock,
  ContentfulImage,
} from "../../shared/contentful/contentful.types";

import "./services.scss";

const renderOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: ReactNode, children: ReactNode) => (
      <p className={"service-card-content"}>{children}</p>
    ),
  },
};

type Props = {
  content: ContentBlock;
};

const ServiceCard = ({ content }: Props) => {
  const { content: textContent, assets, title } = content;
  const asset = assets?.[0] as ContentfulImage;
  const image = getImage(asset?.media as any);
  return (
    <div className={"service-card-wrapper"}>
      {image && <GatsbyImage image={image} alt={asset?.media.description} />}
      <p className={"service-card-title"}>
        <strong>{title}</strong>
      </p>
      {renderRichText(textContent, renderOptions)}
    </div>
  );
};
export default memo(ServiceCard);
