import React, {
  createContext,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { graphql, PageProps } from "gatsby";

import ServiceCarousel from "../components/services/ServiceCarousel.component";
import { Layout } from "../components/layout/Layout.component";
import Service from "../components/services/Service.component";

import { ServiceContent } from "../shared/contentful/contentful.types";
import { PageContextWithNav, SEOContent } from "../shared/page.types";
import { useResponsive } from "../hooks/useResponsive";

import "../components/services/services.scss";
import { getServicesNavLabel } from "../shared/utils/page.utils";

type ServicesProps = {
  pageData: {
    title: string;
    seo: SEOContent;
    content: ServiceContent[];
  };
};

export const ServicesContext = createContext<PageContextWithNav>({
  title: "",
  nav: [],
});

const ServicesPage = (props: PageProps<ServicesProps>) => {
  const {
    data: { pageData },
    location: { href },
  } = props;

  const { content, seo } = pageData;

  const [contextValue, setContextValue] = useState<PageContextWithNav>({
    title: "",
    nav: [],
  });

  const mocapRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<HTMLDivElement>(null);
  const cinematicsRef = useRef<HTMLDivElement>(null);
  const gameAudioRef = useRef<HTMLDivElement>(null);
  const talentRef = useRef<HTMLDivElement>(null);

  const refs: Record<string, RefObject<HTMLDivElement>> = {
    service_mocap: mocapRef,
    service_animation: animationRef,
    service_cinematics: cinematicsRef,
    service_audio: gameAudioRef,
    "service_talent-mgmt": talentRef,
  };

  const mocap = getServicesNavLabel(content, "service_mocap");

  const animation = getServicesNavLabel(content, "service_animation");

  const cinematics = getServicesNavLabel(content, "service_cinematics");

  const audio = getServicesNavLabel(content, "service_audio");

  const talent = getServicesNavLabel(content, "service_talent-mgmt");

  const pageNavItems = [
    {
      label: mocap?.title || "motion capture",
      ref: mocapRef,
      slug: `${mocap?.identifier}`,
    },
    {
      label: animation?.title || "animation",
      ref: animationRef,
      slug: `${animation?.identifier}`,
    },
    {
      label: cinematics?.title || "cinematics",
      ref: cinematicsRef,
      slug: `${cinematics?.identifier}`,
    },
    {
      label: audio?.title || "audio",
      ref: gameAudioRef,
      slug: `${audio?.identifier}`,
    },
    {
      label: talent?.title || "talent",
      ref: talentRef,
      slug: `${talent?.identifier}`,
    },
  ];

  useEffect(() => {
    setContextValue({ nav: pageNavItems, title: "homepage" });
  }, []);

  const { isPortrait, isLarge } = useResponsive();

  const seoProps = {
    ...seo,
    url: href,
  };

  return (
    <ServicesContext.Provider value={contextValue}>
      <Layout pageNavigation={ServiceCarousel} seo={seoProps}>
        {content.map((service, index) => {
          const refName = `${service.identifier}`;
          const ref = refs[`${refName}`] as any;
          return (
            <div className={"service-wrapper"} key={index}>
              <div
                ref={ref}
                id={service.identifier}
                data-active={index}
                className={`section-index ${index === 0 && "row-start-1"}`}
              />
              <Service service={service} showTitle={isPortrait && !isLarge} />
            </div>
          );
        })}
      </Layout>
    </ServicesContext.Provider>
  );
};
export default ServicesPage;

export const query = graphql`
  query servicesPage($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    pageData: contentfulLayoutServices(
      identifier: { eq: "services-page" }
      node_locale: { eq: $language }
    ) {
      title
      content {
        ...service
      }
      seo {
        ...seo
      }
    }
  }
`;
