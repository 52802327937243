import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { Options } from "@contentful/rich-text-react-renderer";
import React, { ReactNode } from "react";
import { ServiceContent } from "../../shared/contentful/contentful.types";
import ServiceCard from "./ServiceCard.component";

import "./services.scss";

type Props = {
  service: ServiceContent;
  showTitle: boolean;
};

const renderOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: ReactNode, children: ReactNode) => (
      <p className={"service-intro"}>{children}</p>
    ),
  },
};

const Service = ({ service, showTitle }: Props) => {
  const { title, thumbnail, content, intro } = service;

  const image = getImage(thumbnail as any);
  return (
    <>
      {showTitle && <h1 className={"service-title"}>{title}</h1>}
      {image && (
        <GatsbyImage
          image={image}
          alt={thumbnail.description}
          className={"header-image"}
        />
      )}
      {renderRichText(intro, renderOptions)}
      {content.map((c, index) => (
        <ServiceCard content={c} key={index} />
      ))}
    </>
  );
};
export default Service;
